
:root {
    --secondary: #E6F7FF!important;
    --accent: #FF9900;
    --background-color: #507eaf;
    --title: #003366;
    --text: #333333;
    --subtext: rgba(0,0,0, 0.5);
}

body {
    margin: 2em;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    background-color: #f5f5f5;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}
h1 {
    font-size: 30px;
    margin-bottom: 0.5em;
}
h2 {
    font-size: 25px;
}
h3 {
    font-size: 20px;
    margin-bottom: 0.5em;
}
h4 {
    font-size: 15px;
}
h5 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.progress-bar {
    background-color: var(--accent)!important;
}

@media screen and (max-width: 576px) {
    
    h1 {
        font-size: 17px;
    }
    h2 {
        font-size: 15px;
    }
    h3 {
        font-size: 13px;
        margin-bottom: 0.5em;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
        color: rgba(0, 0, 0, 0.5);
    }
}
